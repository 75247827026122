import React from 'react'
import Layout from '../components/LayoutHome'
import '../styles/styles.scss'
import Hero from '../components/Hero'

function Index() {
  return (
    <Layout>
      <Hero />
    </Layout>
  )
}

export default Index
