import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Paper from '../images/icons/paper-plane (2).svg'

const News = () => {
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(true)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        if (data.result === 'success') {
          setValid(true)
          return window.open('https://www.richardweale.com/success/')
        } else {
          setValid(false)
        }
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  return (
    <div className="news-form">
      <div className="new-sell">
        <h2>
          JOIN THE MAILING LIST FOR UPDATES
          <br />
          AND A FREE COPY OF THE LIFT
        </h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            name="form-name"
            placeholder="Email"
            onChange={handleEmailChange}
          />
        </div>
        {valid ? null : (
          <div className="valid-email">
            <p>This email is invalid or already taken!</p>
            <p>Please try another...</p>
          </div>
        )}
        <button type="submit">
          SEND MY FREE BOOK
          <img src={Paper} alt="paper airoplane icon for email" />
        </button>
      </form>
    </div>
  )
}

export default News
