import React, { useState } from 'react'
import { Link } from 'gatsby'

const Header = () => {
  const [clickState, setClickState] = useState(false)
  const styles = clickState ? 'show-drop-down' : 'hide-drop-down'

  return (
    <div className="header-wrap">
      <div className="header">
        <Link className="logo-link" to="/">
          <div className="logo-wrap">
            <div className="logo-box"></div>
            <div className="logo">
              <h1>
                RICHARD<span>WEALE</span>
              </h1>
            </div>
          </div>
        </Link>
        <div className="nav">
          <ul>
            <button
              onClick={() => {
                setClickState(!clickState)
              }}
            >
              <span>BOOKS</span>
            </button>

            {/* {clickState ? ( */}
            <ul className={styles}>
              <Link to="/ann-thrope-series">
                ANN THROPE<span>SERIES</span>
              </Link>
              <Link to="/the-lift">POLLY GRANGER</Link>
            </ul>
            {/* ) : null} */}
            <li>
              <Link
                to="/blog"
                activeStyle={{
                  color: '#9746ff',
                }}
              >
                BLOG
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                activeStyle={{
                  color: '#9746ff',
                }}
              >
                ABOUT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
