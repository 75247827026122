import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import News from './New'

const Hero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "assassinbanner.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.image.childImageSharp.fluid
      return (
        <BackgroundImage className={className} fluid={image}>
          <div className="hero-wrap">
            <div className="mission-statement"></div>
            <News />
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(Hero)`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 0;
`

export default StyledBackgroundSection
